import { Injectable } from '@angular/core';
import { TranslationsService } from './translations.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountInfo } from '@azure/msal-browser';
import { shareLinkModel } from '../data/cooptation';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {
  private lang = '';
  // readonly apiURLorigin = 'https://jobs.abylsen.com/backendLaravel/public/api/v1/cooptations';
  readonly apiURLorigin = environment.apiUrl;


  constructor(
    private translationService: TranslationsService,
    private http: HttpClient,

  ) { }

  share(socialMedia: string, jobURL: string, jobTitle?: string) {

    this.lang = this.translationService.getLanguage().toLowerCase();

    switch (socialMedia) {
      case 'mail': this.shareWithMail(jobURL, jobTitle); break;
      case 'twitter': this.shareOnTwitter(jobURL); break;
      case 'linkedin': this.shareOnLinkedIn(jobURL); break;
      case 'facebook': this.shareOnFacebook(jobURL); break;
    }
  }

  private shareOnTwitter(jobURL: string): void {
    let tweetText: string;
    const jobLink = `<a href="${jobURL}">ici</a>`;

    if (this.lang === 'fr') {
      // tweetText = `🚀 Nouvelle opportunité ! Découvrez notre offre et partagez avec vos contacts ! 🌟`;
      tweetText = this.translationService.translate('message_cooptation_twitter_x__start') + `${jobURL}` + this.translationService.translate('message_cooptation_twitter_x__end');
    } else {
      // tweetText = `🚀 New opportunity! Explore our exciting offer and share with your contacts! 🌟`;
      tweetText = this.translationService.translate('message_cooptation_twitter_x__start') + `${jobURL}` + this.translationService.translate('message_cooptation_twitter_x__end');// Default to English
    }

    // const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${jobURL}`;
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;

    window.open(twitterShareUrl, '_blank');
  }

  private shareOnLinkedIn(jobURL: string): void {
    let postText: string;
    const jobLink = `<a href="${jobURL}">ici</a>`;

    if(this.lang === 'fr') {
      postText = this.translationService.translate('message_cooptation_linkedin_start') + `${jobURL}` + this.translationService.translate('message_cooptation_linkedin_end');
    } else { // Default in english
      postText = this.translationService.translate('message_cooptation_linkedin_start') + `${jobURL}` + this.translationService.translate('message_cooptation_linkedin_end');
    }

    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${jobURL}&summary=${encodeURIComponent(postText)}`;

    window.open(linkedInShareUrl, '_blank');
  }

  private shareWithMail(jobURL: string, jobTitle?: string): void {
    let subject: string;
    let body: string;
    const jobLink = `<a href="${jobURL}">ici</a>`;

    if (this.lang === 'fr') {
      subject = 'Offre d\'emploi : ' + jobTitle;
      body = this.translationService.translate('message_cooptation_mails_start') + `${jobURL}` + this.translationService.translate('message_cooptation_mails_end');
    } else { // Default to English
      subject = 'Job Offer : ' + jobTitle;
      body = this.translationService.translate('message_cooptation_mails_start') + `${jobURL}` + this.translationService.translate('message_cooptation_mails_end');
    }
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  }

  // private shareOnMessenger(jobURL: string): void {
  //   let messengerText: string;

  //   if (this.lang === 'fr') {
  //     messengerText = this.translationService.translate('message_cooptation_facebook_before') + jobURL + this.translationService.translate('message_cooptation_facebook_after');
  //   } else { // Default in English
  //     messengerText = this.translationService.translate('message_cooptation_facebook_before') + jobURL + this.translationService.translate('message_cooptation_facebook_after');
  //   }

  //   const shareUrl = `https://votre-site.com/share-job?jobURL=${encodeURIComponent(jobURL)}&message=${encodeURIComponent(messengerText)}`;
  //   const messengerShareUrl = `https://www.facebook.com/dialog/send?link=${encodeURIComponent(shareUrl)}&app_id=VOTRE_APP_ID&redirect_uri=${encodeURIComponent(shareUrl)}`;
  //   window.open(messengerShareUrl, '_blank');
  // }

  private shareOnFacebook(jobURL: string): void {
    let facebookText: string;
    const jobLink = `<a href="${jobURL}">ici</a>`; 

    if (this.lang === 'fr') {
      facebookText = this.translationService.translate('message_cooptation_facebook_before') + `${jobURL}` + this.translationService.translate('message_cooptation_facebook_after');
    } else { // Default in English
      facebookText = this.translationService.translate('message_cooptation_facebook_before') + `${jobURL}` + this.translationService.translate('message_cooptation_facebook_after');
    }

    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(jobURL)}`;
    window.open(facebookShareUrl, '_blank');
  }

  getShareLink(accountInfo: AccountInfo | null, IdOffer?: string): Observable<{ shortLink: string }> {
    console.log('IdOffer', IdOffer);
    const body: shareLinkModel = {
      id_cooptant: accountInfo?.localAccountId,
      cooptant_name: accountInfo?.name,
      cooptant_email: accountInfo?.username,
      IdOffer: IdOffer
    }
    if (IdOffer) {
      this.http.post<{ shortLink: string }>(`${this.apiURLorigin}/cooptation/generate-link`, body).subscribe(p=>console.log('test',p));
      return this.http.post<{ shortLink: string }>(`${this.apiURLorigin}/cooptation/generate-link`, body);
    }
    else
      return this.http.post<{ shortLink: string }>(`${this.apiURLorigin}/cooptation/generate-link`, body);
  }

  getPDFink(): Observable<string> {
    return this.http.get<string>(this.apiURLorigin + '/pdf-link');
  }

}


