export const environment = {
    production: true,
    debugMode: false,
    assetsUrl: '/assets',
    apiUrl: 'https://testjobs.abylsen.com/backendLaravel/public/api/v1/cooptations',
    endpointOrigin: 'https://testjobs.abylsen.com/backendLaravel/public/',
    pdfLink: 'https://testjobs.abylsen.com/backendLaravel/public/api/v1/cooptations/pdf-link',
    siteLink:'https://testcoopt.abylsen.com/',    
    jobApplicationForm: 'resource/cxsrec__cxsForm',
    spontaneousApplicationForm: 'resource/cxsrec__cxsForm',
    linkedInClientId: '78qhafo7x8cbqh',
    clientId: '136b68d6-aff4-438c-b10b-f0b930de8b36',
  };
  