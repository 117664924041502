import { CooptationService } from '../../services/cooptation.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Job } from '../../data/job';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JobSearchService } from '../../services/job-search.service';
import { DialogService } from '../../services/dialog.service';
import { MatRadioChange } from '@angular/material/radio';
import { Agency } from '../../data/agency';
import { TranslationsService } from '../../services/translations.service';
import { SharedMethods } from '../../shared/shared-methods';
import { AuthService } from '../../services/auth.service';
import { AccountInfo } from '@azure/msal-browser';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-cooptation-form',
  templateUrl: './cooptation-form.component.html',
  styleUrl: './cooptation-form.component.scss',
  standalone: false
})
export class CooptationFormComponent implements OnInit {
  recommendationForm: FormGroup;
  selectedFile: File | null = null;
  agencies!: Agency[];
  private dlg?: MatDialogRef<any>;
  public selectedAgency!: Agency | null
  public haveCv: boolean = true
  public offerOption: boolean = true;
  public isDragOver = false;
  public errorMessage: string | null = null; // To store error messages
  public accountInfo!: AccountInfo;
  public cooptantName?: string;
  public isLoading = false;
  selectedJobs: Job[] = [];

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    public service: JobSearchService,
    public dialogService: DialogService,
    public cooptationService: CooptationService,
    private sharedMethod: SharedMethods,
    private translate: TranslationsService,
    private authService: AuthService,


    @Inject(MAT_DIALOG_DATA) public data: { job: Job }

  ) {
    this.recommendationForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      link_linkedin: [''],

      recommandation_level: ['', Validators.required],
      description: ['', Validators.required],
      consent: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
    this.cooptationService.getAgencies().subscribe((res) => {
      this.agencies = res as unknown as Agency[];
    })
    if (this.data?.job) {
      this.selectedJobs.push(this.data.job);
    }
    this.authService.accountInfo$.subscribe(accountInfo => {
      if (accountInfo !== null) {
        this.accountInfo = accountInfo;
      }
    });
  }


  onSubmit(): void {
    if (!this.selectedJobs || this.selectedJobs.length === 0) {
      this.submitSingleCooptationWithNullJob();
    } else {
      this.submitMultipleCooptations();
    }
  }
  
  private submitSingleCooptationWithNullJob(): void {
    this.isLoading = true;
    const formData = this.sharedMethod.createCooptationFormData(
      this.accountInfo,
      this.recommendationForm,
      this.selectedFile,
      this.selectedAgency,
      this.offerOption,
      null 
    );
  
    this.cooptationService.submitCooptation(formData).subscribe({
      next: () => this.handleSuccess('cooptation sent success'),
      error: (error) => this.handleError(error),
    });
  }
  
  private submitMultipleCooptations(): void {
    this.isLoading = true;
    const requests = this.selectedJobs.map((job) => {
      const formData = this.sharedMethod.createCooptationFormData(
        this.accountInfo,
        this.recommendationForm,
        this.selectedFile,
        this.selectedAgency,
        this.offerOption,
        job
      );
      return this.cooptationService.submitCooptation(formData);
    });
  
    forkJoin(requests).subscribe({
      next: () => this.handleSuccess('cooptation sent success'),
      error: (error) => this.handleError(error),
    });
  }
  
  private handleSuccess(message: string): void {
    this.isLoading = false;
    this.sharedMethod.openSnackBar(this.translate.translate(message), 'OK');
    this.dialog.closeAll();
  }
  
  private handleError(error: any): void {
    this.isLoading = false;
    const errorMessage = error.error?.message || this.translate.translate('error sent cooptation');
    this.sharedMethod.openSnackBar(errorMessage, 'OK');
  }

  onRadioChange(event: MatRadioChange) {
    this.haveCv = event.value;
  }

  onRadioOfferChange(event: MatRadioChange) {
    this.offerOption = event.value;
  }



  public showOffers(): void {
    this.dlg = this.dialogService.openDialog();
    this.dlg.afterClosed().subscribe((res: Job) => {
      if (res) {
        if (!this.selectedJobs.some(job => job.uid === res.uid)) {
          this.selectedJobs.push(res);
        }
      }
    });
  }

  public deleteOffer(index: number): void {
    this.selectedJobs.splice(index, 1);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;

    const files = event.dataTransfer?.files;
    if (files) {
      this.handleFileSelection(files);
    }
  }

  deleteFile(): void {
    this.selectedFile = null;
    this.errorMessage = null;
  }


  onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.handleFileSelection(input.files);
    }
  }

  private handleFileSelection(files: FileList) {
    this.errorMessage = null; // Reset error message

    if (files.length > 1) {
      this.errorMessage = 'Only one file is allowed.';
      console.error(this.errorMessage);
      return;
    }

    const file = files[0];
    const allowedFileTypes = ['application/pdf']; // Only allow PDF
    if (!allowedFileTypes.includes(file.type)) {
      this.errorMessage = 'Invalid file type. Only PDFs are allowed.';
      console.error(this.errorMessage);
      return;
    }

    const maxSizeInMB = 5;
    if (file.size > maxSizeInMB * 1024 * 1024) {
      this.errorMessage = 'File is too large. Maximum size is 5MB.';
      console.error(this.errorMessage);
      return;
    }

    this.selectedFile = file; // Store the selected file
  }

}




