import { FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Agency } from "../data/agency";
import { Job } from "../data/job";
import { Injectable } from "@angular/core";
import { AccountInfo } from "@azure/msal-browser";
import { BehaviorSubject } from "rxjs";


@Injectable({
    providedIn: 'root'
})

export class SharedMethods {


    constructor(private _snackBar: MatSnackBar) { }


    // Example shared method
    public static showSnackBar(snackBar: MatSnackBar, message: string, action: string = 'OK', duration: number = 5000) {
        snackBar.open(message, action, {
            duration: duration,
        });
    }




    public createCooptationFormData(accountInfo: AccountInfo, f: FormGroup, selectedFile: File | null, selectedAgency: Agency | null, offerOption: boolean, job: Job | null): FormData {

        const formData = new FormData();
        Object.keys(f.value).forEach(key => {
            if (key !== 'consent')
                formData.append(key, f.get(key)?.value);
        });
        //File or Job selected
        if (selectedFile)
            formData.append('cv_path', selectedFile);
        // Cooptation on Agency
        if (selectedAgency && !offerOption) {
            selectedAgency.name ? formData.append('agency', selectedAgency.name): console.error('error agency missing');
            selectedAgency.country ? formData.append('country', selectedAgency.country) : console.error('error country missing');
            formData.append('company', 'Abylsen');

        } 
        // Cooptation on Offer
        if(!selectedAgency && job){
            job.uid ? formData.append('id_offre', job.uid) : console.error('error id offer missing');
            job.location ? formData.append('agency', job.location): console.error('error job location missing');
            job.country ? formData.append('country', job.country): console.error('error job country missing');
            job.company ? formData.append('company', job.company): console.error('error job company missing');
        }
        //accountInfo
        accountInfo.localAccountId ? formData.append('id_cooptant', accountInfo.localAccountId) : console.log('error id_cooptant missing'); //Get localAccountId
        accountInfo.name ? formData.append('cooptant_name', accountInfo.name) :  console.log('error cooptant_name missing');
        accountInfo.username ?formData.append('cooptant_email', accountInfo.username) :  console.log('error cooptant_email missing');
        return formData
    }


    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 5000,
        });
    }


    private titleSubject = new BehaviorSubject<string>('Default Title');
    currentTitle$ = this.titleSubject.asObservable();

    public updateTitle(newTitle: string) {
        this.titleSubject.next(newTitle);
    }


}